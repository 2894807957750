<template>
  <div class="vote">
    <div
      class="vote__down-vote"
      @click.prevent="downvote(id)"
      :class="[{ voted: num < likes }]"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 9L12 15L18 9"
          stroke="#8A939A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div
      class="vote__likes"
      :class="[
        { down: num < likes },
        { up: num > likes },
        { positive: num > 0 },
        { negative: num < 0 },
      ]"
    >
      <span class="vote__likes-n">{{ likes + 1 }}</span>
      <span class="vote__likes-n">{{ likes }}</span>
      <span class="vote__likes-n"> {{ likes - 1 }}</span>
    </div>
    <div
      class="vote__up-vote"
      @click.prevent="upvote(id)"
      :class="[{ voted: num > likes }]"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 15L12 9L6 15"
          stroke="#8A939A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import {ref, watch} from 'vue'
import {post} from '../api/index'
export default {
  props: {
    likes: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
    isLiked: {
      type: Boolean,
    },
    isDisliked: {
      type: Boolean,
    },
  },
  data() {
    return {
      num: this.likes,
      liked: this.isLiked,
      disliked: this.isDisliked,
    };
  },
  mounted() {
    if (this.liked) {
      this.num = this.likes + 1;
    } else if (this.disliked) {
      this.num = this.likes - 1;
    }
  },
  methods: {
    downvote(id) {
      if (this.num !== this.likes - 1) {
        this.num -= 1;
        console.log(this.id)
        
        post("/comment/change-likes", {
          id: this.id,
          likes: Number(this.num),
        });
      }
    },
    upvote(id) {
      if (this.num !== this.likes + 1) {
        this.num += 1;
        post("/comment/change-likes", {
          id: this.id,
          likes: Number(this.num),
        });
      }
    },
  },
};
</script>
<style>
.vote {
display: flex;
align-items: center;
}
.vote svg path {
stroke: #595959;
}
.vote__likes {
min-width: 30px;
height: 24px;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
text-align: center;
color: #595959;
display: flex;
align-items: center;
user-select: none;
justify-content: center;
position: relative;
display: flex;
flex-direction: column;
max-height: 20px;
overflow: hidden;
}
.vote__likes .vote__likes-n {
transition: all 0.4s ease;
}
.vote__likes.up .vote__likes-n {
transform: translateY(100%);
}
.vote__likes.down .vote__likes-n {
transform: translateY(-100%);
}
.vote__likes.negative {
color: #d34f57;
}
.vote__likes.positive {
color: #2ea83a;
}
.vote__down-vote {
border-radius: 50%;
}
.vote__down-vote.voted {
background: rgba(211, 79, 87, 0.1);
}
.vote__down-vote.voted svg path {
stroke: #d34f57;
}
.vote__down-vote.voted:hover {
background: rgba(211, 79, 87, 0.2);
}
.vote__down-vote:hover {
background: rgba(0, 0, 0, 0.05);
}
.vote__up-vote {
border-radius: 50%;
}
.vote__up-vote.voted {
background: rgba(79, 167, 87, 0.1);
}
.vote__up-vote.voted svg path {
stroke: #2ea83a;
}
.vote__up-vote.voted:hover {
background-color: rgba(79, 167, 87, 0.2);
}
.vote__up-vote:hover:hover {
background: rgba(0, 0, 0, 0.05);
}

</style>