<template>
  <div class="articles-banner">
    <h1>{{ articleDetail.name }}</h1>
    <div class="ab-from">{{ "From " + articleDetail.source }}</div>
    <div class="ab-info">
      <span class="icon-'.$topic.'">{{ articleDetail.topic }}</span>
      <span class="icon-'.$category.'">{{ articleDetail.category }}</span>
      <span>{{ articleDetail.readingTimes }} mins</span>
    </div>
    <div class="ab-url">
      <a :href="articleDetail.link" target="_blank">{{ articleDetail.link }}</a>
    </div>
  </div>

  <el-tabs class="tab">
    <el-tab-pane style="margin-top: 10px;">
      <template #label>
        <el-button style="font-weight: bold; font-size: 15px;" text size="small">Questionnaire</el-button>
      </template>
      <div class="articles-wrap">
        <div class="ad-tips">
          Please read the article and return to this
          page to answer questions.
        </div>
        <div class="ad-title">
          General Questions
        </div>
        <div class="ad-box">
          <div class="ad-item" v-for="(question, index) in genQues" :key="index">
            <div class="adi-number">{{ index + 1 }}</div>
            <div class="adi-text">
              <div class="adi-title">{{ question }}</div>
              <div class="adi-con" v-if="index !== 6">
                <label class="adi-label" v-for="{ option, label } in q1256" :key="option">
                  <input
                    class="adi-radio"
                    type="radio"
                    v-model="genAns[index]"
                    :value="`${option}|${label}`"
                  />
                  {{ label }}
                </label>
              </div>
              <div class="adi-con" v-else>
                <textarea
                  class="ad-textarea"
                  name="additionalComments"
                  placeholder="e.g. what do you think about this article"
                  v-model="genAns[6]"
                  style="width: 800px"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="articles-wrap">
        <div class="ad-title">
          Specific Questions
        </div>
        <div class="ad-box">
          <div class="ad-item" v-for="(question, index) in questions" :key="question.articleId">
            <div class="adi-number">{{ index + 1 }}</div>
            <div class="adi-text">
              <div class="adi-title">{{ question.question }}</div>
              <div class="adi-con">
                <label class="adi-label-block" v-for="choice in [1, 2, 3, 4]" :key="choice">
                  <input
                    type="radio"
                    :name="index"
                    v-model="spcAns[index]"
                    class="adi-radio"
                    :value="choice"
                  />
                  {{ question['choice' + choice] }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ad-btn">
        <div class="form-btn" @click="submit">Submit and Proceed to Exploration</div>
        <el-button v-if="userLevel === 3 || userLevel === 2" type="primary" @click="showModifyDialog">Modify</el-button>
      </div>
    </el-tab-pane>
    <el-tab-pane>
      <template #label>
        <el-badge :value="comment.length">
          <el-button style="font-weight: bold; font-size: 15px;" text size="small">Comment</el-button>
        </el-badge>
      </template>
      <div
        class="my-[100px] max-w-[600px] mx-auto"
        :key="componentKey"
        style="width: 1100px; margin: 10px auto; font-size: 15px;"
      >
        <div v-show="!hasComments">
          There exists no comments for this article now.
          <br />
          Be the first to comment!
          <br />
          <textarea
            class="comment_reply"
            placeholder="e.g. what do you think about this article"
            v-model="firstComment"
            style="width: 1000px; font-size: 15px;"
          ></textarea>
          <div class="firstCommentSubmit" @click="submitComment(-1, articleDetail.id, firstComment)">
            submit
          </div>
        </div>

        <div v-for="(item, index) in comment" :key="index">
          <NestedComment
            @toFather="fromChild"
            @getCommentb="getComment"
            v-bind="{
              avatar: item.image,
              author: item.author,
              content: item.content,
              replies: item.replies,
              timeAdded: item.timeAdded,
              is_liked: item.is_liked,
              is_disliked: item.is_disliked,
              id: item.id,
              likes: item.likes,
              date: item.date,
              articleId: item.articleId,
              userId: item.userId
            }"
          />
        </div>

        <div v-show="hasComments">
          <textarea
            class="comment_reply_common"
            placeholder="e.g. what do you think about this article"
            v-model="myComment"
            style="width: 800px"
          ></textarea>
          <div class="firstCommentSubmit" @click="submitMyComment(-1, articleDetail.id, myComment)">
            submit
          </div>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>

  <el-dialog v-model="modifyDialogVisible" title="Modify Article">
    <el-button type="danger" @click="handleDelete">Delete</el-button>
    <el-button type="primary" @click="handleModerateArticle">Moderate Article</el-button>
    <el-button type="primary" @click="handleModerateQuestion">Moderate Question</el-button>
    <template #footer>
      <el-button @click="modifyDialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="confirmModify">Confirm</el-button>
    </template>
  </el-dialog>

  <el-dialog v-model="countdownVisible" title="Redirecting to Article" @close="handleCountdownClose">
    <div>
      Please return to the page after finish reading the news.
      Redirecting in {{ countdown }} seconds...
    <br />
    <br />
      <el-button type="primary" @click="handleManualRedirect">Go to News Page</el-button>
    </div>
  </el-dialog>
</template>


<script>
import { ref, onUpdated, onMounted, onBeforeUnmount,computed } from "vue";
import { get, post } from "@/api";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import NestedComment from "@/components/NestedComment";
import { formatDateTime } from "@/utils/dateFormatter";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    NestedComment,
  },
  name: "Article",
  setup() {
    
    const fromChild = (data) => {
      getComment();
    };

    const genQues = computed(() => {
      const itemType = articleDetail.value.category === 'Video' ? 'video' : 'article';
      return [
        `How much do you like the ${itemType}?`,
        `How useful do you find this ${itemType}?`,
        `How accurate do you think this ${itemType} is?`,
        `How confident do you feel about your accuracy judgment on this ${itemType}?`,
        `How much did your prior view on this subject change after viewing this ${itemType}?`,
        `How clear do you think this ${itemType} is?`,
        "Please provide any additional comments",
      ];
    });

    const route = useRoute();
    const router = useRouter();
    const uid = ref(-1);
    const uname = ref("");
    const ucomment = ref("");
    const userLevel = ref(0);
    const questions = ref([]);
    const spcAns = ref([]);
    const genAns = ref(new Array(7).fill(""));
    const hasComments = ref(false);
    const firstComment = ref("");
    const myComment = ref("");
    const modifyDialogVisible = ref(false);
    const componentKey = ref(0);
    const articleDetail=ref({});
    const q1256 = [
      { option: "1", label: "Not at all" },
      { option: "2", label: "Not very much" },
      { option: "3", label: "Somewhat" },
      { option: "4", label: "Very much" },
    ];
    const q3_value = [
      { option: "1", label: "Fake news" },
      { option: "2", label: "Significantly inaccurate/misleading" },
      { option: "3", label: "Mostly accurate" },
      { option: "4", label: "Accurate" },
    ];
    const q4_value = [
      { option: "1", label: "Not confident" },
      { option: "2", label: "Educated guess" },
      { option: "3", label: "Fairly confident" },
      { option: "4", label: "Very confident" },
    ];
    const comment = ref([]);
    const isSubmitting = ref(false);

    const countdown = ref(5);
    const countdownVisible = ref(false);
    let countdownInterval;

    function loadArticleDetails() {
      const storedDetails = sessionStorage.getItem('articleDetail');
      if (storedDetails) {
        articleDetail.value = JSON.parse(storedDetails);
        console.log(articleDetail)
        getComment();
        // sessionStorage.removeItem("articleDetail");
      } else {
        console.log("No article details found in sessionStorage.");
      }
    }

    function startCountdown() {
      countdownVisible.value = true;
      countdownInterval = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value -= 1;
        } else {
          clearInterval(countdownInterval);
          redirectToArticle();
        }
      }, 1000);
    }

    function redirectToArticle() {
      console.log("redirect " + articleDetail.value.link);
      setTimeout(() => {
        window.open(articleDetail.value.link, "_blank");
        countdownVisible.value = false;
      }, 100);
      countdownVisible.value = false;
    }

    function handleManualRedirect() {
      clearInterval(countdownInterval);
      redirectToArticle();
    }

    function handleCountdownClose() {
      clearInterval(countdownInterval);
    }

    function convert(data) {
      comment.value = [];
      let map = {};
      data.forEach((item) => {
        map[item.id] = item;
      });
      data.forEach((item) => {
        if (item.status == 1) {
          if (item.parentId != -1) {
            let parent = map[item.parentId];
            if (parent) {
              (parent.replies || (parent.replies = [])).push(item);
            }
          } else {
            comment.value.push(item);
          }
        }
      });
    }

    function getComment() {
      get("/comment/getComment/" + articleDetail.value.id).then((res) => {
        const temp = res.comments;
        temp.forEach((item) => {
          item.replies = [];
          post("/user/get-user-name", { uid: item.userId }).then((res) => {
            item.author = res.uname;
          });
          item.timeAdded = formatDateTime(new Date(item.timeAdded), "yyyy-MM-dd HH:mm:ss");
        });
        convert(temp);
        hasComments.value = comment.value.length;
        console.log("comment Value" + comment.value.length);
      });
    }
    // getComment();

    function submitComment(parentId, articleId, content) {
      if (content !== "" && !isSubmitting.value) {
        isSubmitting.value = true;
        if (uid.value !== -1) {
          if (ucomment.value === 1) {
            post("/comment/save", {
              articleId: articleId,
              userId: uid.value,
              content: content,
              parentId: parentId,
              status: 1,
            }).then((res) => {
              componentKey.value += 1;
              firstComment.value = ""; // Clear the first comment input
              isSubmitting.value = false;
              getComment();
            });
          } else {
            console.log("article moderation");
            ElMessageBox.alert("This reply is waiting for moderation", "Notice", {
              confirmButtonText: "OK",
            });
            post("/comment/save", {
              articleId: articleId,
              userId: uid.value,
              content: content,
              parentId: parentId,
              status: 0,
            }).then((res) => {
              componentKey.value += 1;
              firstComment.value = "";
              isSubmitting.value = false;
              getComment();
            });
          }
        } else {
          ElMessageBox.alert("Please login to reply!", "Notice", {
            confirmButtonText: "OK",
          });
          isSubmitting.value = false;
        }
      } else {
        ElMessageBox.alert("Please write something to submit!", "Notice", {
          confirmButtonText: "OK",
        });
        isSubmitting.value = false;
      }
    }

    function submitMyComment(parentId, articleId, content) {
      if (content !== "" && !isSubmitting.value) {
        isSubmitting.value = true;
        if (uid.value !== -1) {
          if (ucomment.value === 1) {
            post("/comment/save", {
              articleId: articleId,
              userId: uid.value,
              content: content,
              parentId: parentId,
              status: 1,
            }).then((res) => {
              componentKey.value += 1;
              myComment.value = "";
              isSubmitting.value = false;
              getComment();
            });
          } else {
            // console.log("article moderation22");
            ElMessageBox.alert("This reply is waiting for moderation", "Notice", {
              confirmButtonText: "OK",
            });
            post("/comment/save", {
              articleId: articleId,
              userId: uid.value,
              content: content,
              parentId: parentId,
              status: 0,
            }).then((res) => {
              componentKey.value += 1;
              myComment.value = "";
              isSubmitting.value = false;
              getComment();
            });
          }
        } else {
          ElMessageBox.alert("Please login to reply!", "Notice", {
            confirmButtonText: "OK",
          });
          isSubmitting.value = false;
        }
      } else {
        ElMessageBox.alert("Please write something to submit!", "Notice", {
          confirmButtonText: "OK",
        });
        isSubmitting.value = false;
      }
    }

    function openActivity() {
      post("/activity/open-article", {
        uid: uid.value,
        uname: uname.value,
        articleId: Number(articleDetail.value.id),
        articleName: articleDetail.value.name,
      }).then(() => {
        if (uid.value !== -1) {
          post("/point/open-article", {
            uid: uid.value,
            articleId: Number(articleDetail.value.id),
          }).then((res) => {
            if (res.res === 1) {
              ElMessage({
                message: "You opened a new article! 2 points will be rewarded!",
                type: "success",
              });
              countdown.value = 5;
              countdownVisible.value = true;
              startCountdown();
            } else {
              countdown.value = 5;
              countdownVisible.value = true;
              startCountdown();
            }
          });
        } else {
          countdown.value = 5;
          countdownVisible.value = true;
          startCountdown();
        }
      }).catch((error) => {
        console.error("Error in openActivity:", error);
      });
    }

    function load() {
      console.log(articleDetail.value.id);
      post("/question/get-question", { articleId: articleDetail.value.id })
        .then((res) => {
          questions.value = res.questions;
          spcAns.value = new Array(questions.value.length).fill("");
          console.log(questions.value);
        })
        .catch((error) => {
          console.error("Failed to load questions:", error);
        });

      if (sessionStorage.getItem("token")) {
        post(`/user/logged`)
          .then((res) => {
            if (res.res === 1) {
              uid.value = res.user.uid;
              uname.value = res.user.uname;
              ucomment.value = res.user.commentAuthority;
              userLevel.value = res.user.admin;
              console.log(uid.value);
              console.log(userLevel.value);
            } else {
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("Authorization");
            }
            openActivity();
          })
          .catch((error) => {
            console.error("Failed to check user login:", error);
          });
      } else {
        openActivity();
      }
    }

    async function submit() {
      try {
        await ElMessageBox.confirm("You are submitting your answer(s). Continue?", "Attention", {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        });

        const generalAnswers = [];
        const specificAnswers = [];

        // Collect general answers, excluding the 7th question (comment)
        for (let genAnsKey in genAns.value) {
          if (genAnsKey != 6 && genAns.value[genAnsKey] !== "") {
            generalAnswers.push({
              articleId: articleDetail.value.id,
              articleName: articleDetail.value.name,
              questionId: Number(genAnsKey) + 1,
              question: genQues.value[genAnsKey],
              answerChoice: genAns.value[genAnsKey].split("|")[0],
              answerLabel: genAns.value[genAnsKey].split("|")[1],
              userId: uid.value,
              user: uname.value,
            });
          }
        }

        // Collect specific answers, even if not all are filled
        for (let spcAnsKey in spcAns.value) {
          if (spcAns.value[spcAnsKey] !== "") {
            let choice = "choice" + spcAns.value[spcAnsKey];
            specificAnswers.push({
              articleId: articleDetail.value.id,
              articleName: articleDetail.value.name,
              questionId: questions.value[spcAnsKey].questionId,
              question: questions.value[spcAnsKey].question,
              answerChoice: spcAns.value[spcAnsKey],
              answerLabel: questions.value[spcAnsKey][choice],
              userId: uid.value,
              user: uname.value,
            });
          }
        }

        const payload = {
          generalAnswers: generalAnswers,
          specificAnswers: specificAnswers,
        };

        // Submit answers
        const response = await post("/answer/save", payload);

        if (response.res !== 1) {
          ElMessage.error("Submission of answers failed. Try again.");
          return;
        }

        // Submit the 7th general answer as a comment
        if (genAns.value[6] !== "") {
          await submitComment(-1, articleDetail.value.id, genAns.value[6]);
        }

        // Submit comments if any
        if (firstComment.value !== "") {
          await submitComment(-1, articleDetail.value.id, firstComment.value);
        }
        if (myComment.value !== "") {
          await submitMyComment(-1, articleDetail.value.id, myComment.value);
        }

        await post("/answer/get-attempts").catch((error) => {
          console.error("Error calling get-attempts API:", error);
        });
        ElMessage.success("You have submitted your answer(s). Points rewarded.");
        router.push("/");

        spcAns.value = new Array(questions.value.length).fill("");
        genAns.value = new Array(7).fill("");
        firstComment.value = "";
        myComment.value = "";
      } catch (error) {
        ElMessage({
          type: "info",
          message: "Canceled.",
        });
      }
    }

    async function confirmModify() {
      try {
        ElMessage.success("Article modification confirmed.");
        modifyDialogVisible.value = false;
      } catch (error) {
        ElMessage.error("Modification failed. Try again.");
      }
    }

    function showModifyDialog() {
      modifyDialogVisible.value = true;
    }

    async function handleDelete() {
      try {
        await ElMessageBox.confirm(
          "Are you sure to delete this article record?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        const response = await post("/admin/delete-article", { articleId: articleDetail.value.id, moderator:uid.value });

        if (response.res === 1) {
          ElMessage.success("Delete completed");
          modifyDialogVisible.value = false;
          router.push("/");
        } else {
          ElMessage.error("Error, delete canceled");
        }
      } catch (error) {
        ElMessage({
          type: "info",
          message: "Delete canceled",
        });
      }
    }

    function handleModerateArticle() {
      // Save question and article information to sessionStorage
      sessionStorage.setItem("newId", articleDetail.value.id);
      sessionStorage.setItem("reviewStatus", "modifyarticlelv3");
      sessionStorage.setItem("newArticle", JSON.stringify({
        id: articleDetail.value.id,
        name: articleDetail.value.name,
        source: articleDetail.value.source,
        topic: articleDetail.value.topic,
        category: articleDetail.value.category,
        readingTimes: articleDetail.value.readingTimes,
        overview: articleDetail.value.overview,
        link: articleDetail.value.link,
      }));
      // Redirect to articleModerate page
      router.push("/article-add");
    }

    function handleModerateQuestion() {
      sessionStorage.setItem("articleId", articleDetail.value.id);
      sessionStorage.setItem("reviewStatus", "modifyquestionlv3");
      sessionStorage.setItem("newArticle", JSON.stringify({
        id: articleDetail.value.id,
        name: articleDetail.value.name,
        source: articleDetail.value.source,
        topic: articleDetail.value.topic,
        category: articleDetail.value.category,
        readingTimes: articleDetail.value.readingTimes,
        overview: articleDetail.value.overview,
        link: articleDetail.value.link,
      }));
      // Redirect to articleModerate page
      router.push("/questions-add");
    }

    onUpdated(() => {
      console.log(spcAns.value);
    });

    onMounted(() => {
      loadArticleDetails();
      load();
    });

    onBeforeUnmount(() => {
      clearInterval(countdownInterval);
    });

    return {
      genQues,
      questions,
      spcAns,
      genAns,
      q1256,
      q3_value,
      q4_value,
      submit,
      route,
      hasComments,
      submitComment,
      submitMyComment,
      firstComment,
      comment,
      componentKey,
      getComment,
      fromChild,
      modifyDialogVisible,
      showModifyDialog,
      confirmModify,
      handleDelete,
      handleModerateArticle,
      handleModerateQuestion,
      userLevel,
      isSubmitting,
      countdown,
      countdownVisible,
      handleManualRedirect,
      handleCountdownClose,
      articleDetail,
    };
  },
});
</script>



<style scoped>
.el-tabs__item {
  margin-right: 3px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border: 2px solid #eee;
  background: #797979;
  color: #a6a6a6;
  padding: 0 40px;
}
.comment-input {
  width: 100%;
  font-size: 15px;
  padding: 20px 20px;
  background-color: #eff1f5;
  border: none;
  border-radius: 15px;
  min-height: 100px;
  margin-top: 10px;
}

.commenttt ul {
  padding-left: 16px;
  margin: 6px 0;
  font-size: 20px;
}
.tab {
  border: 1px dotted gray;
  padding: 30px;
  width: 1200px;
  margin: 30px auto;
  border-radius: 50px;
}
.comments {
  width: 1100px;
  margin: 0 auto 0 auto;
  padding-bottom: 10px;
  margin-top: 20px;
}
.card-header {
  font-size: 20px;
  font-family: "Poppins-Medium";
}

.comment_content {
  font-size: 15px;
}

.comment_reply {
  width: 100%;
  font-size: 10px;
  padding: 20px 20px;
  background-color: #eff1f5;
  border: none;
  border-radius: 10px;
  min-height: 10px;
  margin-top: 10px;
}

.comment_reply_common{
  width: 100%;
  font-size: 10px;
  padding: 20px 20px;
  background-color: #EFF1F5;
  border: none;
  border-radius: 10px;
  min-height: 10px;
  margin-top: 20px;
  margin-left: 20px;
}
.firstCommentSubmit {
display: block;
width: 100px;
height: 20px;
font-size: 10px;
color: #fff !important;
line-height: 20px;
text-align: center;
border: none;
margin: 0 auto;
border-radius: 15px;
cursor: pointer;
font-family: "Poppins-Black";
background-color: #5473ff;
box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
margin-top: 10px;
margin-left: 20px;
}
.firstCommentSubmit:hover {
  background-color: #4261f0;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}

.articles-banner {
  display: block;
  margin: 0 auto;
  width: 1260px;
  height: 180px;
  padding: 25px 0;
  color: #ffffff;
  background-color: #5473ff;
  text-align: center;
  border-radius: 0 0 80px 80px;
}
.articles-banner h1 {
  font-size: 22px;
  font-family: "Poppins-Bold";
}
.articles-banner .ab-from {
  margin-top: 16px;
  font-size: 20px;
  font-style: italic;
  color: #e8e8e8;
}
.articles-banner .ab-info {
  display: flex;
  margin-top: 15px;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}
.articles-banner .ab-info span {
  display: flex;
  align-items: center;
  margin: 0 18px;
}
.articles-banner .ab-info span img {
  margin-right: 6px;
  width: 19px;
  height: 19px;
}
.articles-banner .ab-url {
  font-size: 16px;
  margin-top: 22px;
}
.articles-banner .ab-url a {
  text-decoration: underline;
  color: #ffffff;
}
.articles-banner .ab-url a:hover {
  color: #d0f2fc;
}

.add-box.preview .articles-banner {
  width: 100%;
  background-color: #98ca9f;
}

.articles-wrap {
  display: block;
  width: 1100px;
  margin: 0 auto 0 auto;
  padding-bottom: 10px;
}
.add-box.preview .articles-wrap {
  width: 100%;
}

.ad-tips {
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #14279b;
  font-family: "Poppins-Medium";
  margin-top: 15px;
  margin-bottom: 25px;
  align-items: center;
  justify-content: center;
}
.ad-tips img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.ad-title {
  display: flex;
  text-align: center;
  font-size: 24px;
  color: #14279b;
  font-family: "Poppins-Bold";
  margin-top: 35px;
  margin-bottom: 35px;
  align-items: center;
  justify-content: center;
}
.ad-title img {
  width: 29px;
  height: 29px;
  margin-right: 10px;
}

.ad-box {
  display: block;
  width: 100%;
  margin-top: 30px;
  padding: 15px;
  height: auto;
  border-radius: 30px;
  margin-bottom: 15px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.ad-item {
  display: flex;
  padding: 35px 0;
  border-bottom: 1px solid #e5e5e5;
}
.ad-item:last-child {
  border-bottom: none;
}
.ad-item .adi-number {
  flex: 0 0 8%;
  width: 8%;
  display: flex;
  align-items: center;
  font-size: 32px;
  color: #969696;
  font-family: "Poppins-Bold";
}
.ad-item .adi-text {
  flex: 0 0 92%;
  width: 92%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ad-item .adi-title {
  font-size: 16px;
  color: #000000;
  font-family: "Poppins-Bold";
  margin-bottom: 22px;
}
.ad-item .adi-con {
  display: block;
  width: 1000px;
  font-size: 14px;
}
.ad-item .adi-label {
  display: inline-block;
  font-size: 14px;
  padding-right: 4%;
}
.ad-item .adi-label-block {
  display: block;
  width: 100%;
  font-size: 14px;
  padding-right: 0;
  margin: 10px 0;
}
.ad-item .adi-label .adi-radio,
.ad-item .adi-label-block .adi-radio {
  width: 19px;
  height: 19px;
  vertical-align: -4px;
  margin-right: 12px;
}

.ad-textarea {
  width: 100%;
  font-size: 15px;
  padding: 20px 20px;
  background-color: #eff1f5;
  border: none;
  border-radius: 15px;
  min-height: 100px;
}
.ad-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 20px;
  padding-bottom: 90px;
  position: relative;
}
.ad-btn .form-btn {
  width: 530px;
}

.form-btn {
  display: block;
  width: 180px;
  height: 50px;
  font-size: 20px;
  color: #fff !important;
  line-height: 50px;
  text-align: center;
  border: none;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Poppins-Black";
  background-color: #5473ff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.form-btn:hover {
  background-color: #4261f0;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.ad-btn .form-btn {
  width: 530px;
}
</style>
